import React, { useState, useEffect } from 'react';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { motion, AnimatePresence } from 'framer-motion';
import { Share2, ChevronRight, User, Users, Book, Globe, Loader, Feather, Home, Info, Mail, Heart, MapPin } from 'lucide-react';
import { KofiButton } from "react-kofi-button";
import { GB, ES, CN, IN, SA, FR, BD, RU, PT, PK, DE, JP, TZ, KR, IT } from 'country-flag-icons/react/3x2';

const genAI = new GoogleGenerativeAI("AIzaSyCxxd1RCPsGvZPX26BaoykmN9qlFDB8dEo");

const STEPS = ['NAME', 'TOPIC', 'LANGUAGE', 'PRAYER'] as const;

const PROMPTS = {
  NAME: "Let's start with your name, city, and who this prayer is for",
  TOPIC: "What would you like to pray about?",
  LANGUAGE: "Select the language for your prayer",
  PRAYER: "Here's your personalized prayer and a relevant Bible verse"
};

const LANGUAGES = [
  { code: 'en', name: 'English', flag: GB, popularity: 1 },
  { code: 'es', name: 'Spanish', flag: ES, popularity: 0.9 },
  { code: 'zh', name: 'Chinese (Mandarin)', flag: CN, popularity: 0.85 },
  { code: 'hi', name: 'Hindi', flag: IN, popularity: 0.8 },
  { code: 'ar', name: 'Arabic', flag: SA, popularity: 0.75 },
  { code: 'fr', name: 'French', flag: FR, popularity: 0.7 },
  { code: 'bn', name: 'Bengali', flag: BD, popularity: 0.65 },
  { code: 'ru', name: 'Russian', flag: RU, popularity: 0.6 },
  { code: 'pt', name: 'Portuguese', flag: PT, popularity: 0.55 },
  { code: 'ur', name: 'Urdu', flag: PK, popularity: 0.5 },
  { code: 'de', name: 'German', flag: DE, popularity: 0.45 },
  { code: 'ja', name: 'Japanese', flag: JP, popularity: 0.4 },
  { code: 'sw', name: 'Swahili', flag: TZ, popularity: 0.35 },
  { code: 'ko', name: 'Korean', flag: KR, popularity: 0.3 },
  { code: 'it', name: 'Italian', flag: IT, popularity: 0.25 },
];

const POPULAR_PRAYER_TOPICS = [
  { topic: 'Health', popularity: 1 },
  { topic: 'Family', popularity: 0.95 },
  { topic: 'Guidance', popularity: 0.9 },
  { topic: 'Peace', popularity: 0.85 },
  { topic: 'Strength', popularity: 0.8 },
  { topic: 'Forgiveness', popularity: 0.75 },
  { topic: 'Love', popularity: 0.7 },
  { topic: 'Faith', popularity: 0.65 },
  { topic: 'Hope', popularity: 0.6 },
  { topic: 'Wisdom', popularity: 0.55 },
  { topic: 'Protection', popularity: 0.5 },
  { topic: 'Healing', popularity: 0.45 },
  { topic: 'Gratitude', popularity: 0.4 },
  { topic: 'Patience', popularity: 0.35 },
  { topic: 'Courage', popularity: 0.3 },
  { topic: 'Prosperity', popularity: 0.25 },
  { topic: 'Comfort', popularity: 0.2 },
  { topic: 'Joy', popularity: 0.15 },
  { topic: 'Purpose', popularity: 0.1 },
  { topic: 'Relationships', popularity: 0.05 },
];

interface FormData {
  name: string;
  city: string;
  prayerFor: string;
  prayerTopic: string;
  language: string;
}

interface PrayerResponse {
  prayer: string;
  bibleVerse: {
    text: string;
    reference: string;
  };
}

export default function PrayerPartnerWebsite() {
  const [step, setStep] = useState<number>(0);
  const [formData, setFormData] = useState<FormData>({
    name: '',
    city: '',
    prayerFor: '',
    prayerTopic: '',
    language: '',
  });
  const [prayerResponse, setPrayerResponse] = useState<PrayerResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [contactForm, setContactForm] = useState({ name: '', email: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [captcha, setCaptcha] = useState({ question: '', answer: 0 });
  const [userCaptchaAnswer, setUserCaptchaAnswer] = useState('');
  const [isApiUnavailable, setIsApiUnavailable] = useState(false);

  useEffect(() => {
    generateCaptcha();
  }, []);

  useEffect(() => {
    if (prayerResponse) {
      const text = `${prayerResponse.prayer} ${prayerResponse.bibleVerse.text} ${prayerResponse.bibleVerse.reference}`;
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = formData.language;
      
      window.speechSynthesis.speak(utterance);
      return () => {
        window.speechSynthesis.cancel();
      };
    }
  }, [prayerResponse, formData.language]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'radio' ? value : (name === 'prayerTopic' ? value : value.trim())
    }));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const nextStep = () => {
    if (step < STEPS.length - 1) {
      setStep(prev => prev + 1);
    }
  };

  const extractJSONFromMarkdown = (text: string): string => {
    const jsonMatch = text.match(/```json\s*([\s\S]*?)\s*```/);
    if (jsonMatch) {
      try {
        return JSON.stringify(JSON.parse(jsonMatch[1]));
      } catch (e) {
        console.error('Failed to parse extracted JSON:', e);
        return '';
      }
    }
    return text;
  };

  const generatePrayer = async () => {
    setIsLoading(true);
    setError('');
    try {
      const model = genAI.getGenerativeModel({ model: "gemini-pro" });

      const prompt = `Generate a personalized prayer for ${formData.name} from ${formData.city}. The prayer is for ${formData.prayerFor === 'self' ? 'themselves' : 'others'} and the topic is ${formData.prayerTopic}. The prayer should be longer and more detailed, approximately 150-200 words. Also, provide a relevant Bible verse with its reference. The prayer and Bible verse should be in ${LANGUAGES.find(lang => lang.code === formData.language)?.name || 'English'}.

    Format the response as a JSON object with the following structure:
    {
      "prayer": "The generated prayer text in the specified language",
      "bibleVerse": {
        "text": "The Bible verse text in the specified language",
        "reference": "The Bible verse reference"
      }
    }

    Ensure that the response is a valid JSON object without any markdown formatting or special characters that could break JSON parsing.`;

      const result = await model.generateContent(prompt);
      const response = result.response;
      const text = response.text();

      const jsonText = extractJSONFromMarkdown(text);
      if (!jsonText) {
        throw new Error('Failed to extract valid JSON from the response');
      }
      const prayerResponse: PrayerResponse = JSON.parse(jsonText);
      setPrayerResponse(prayerResponse);
      nextStep();
    } catch (error) {
      console.error('Error generating prayer:', error);
      if (error instanceof Error) {
        if (error.message.includes("API Key") || error.message.includes("consumer identity")) {
          setError("We're experiencing technical difficulties with our prayer generation service. Please try again later or contact support if the issue persists.");
          setIsApiUnavailable(true);
        } else {
          setError(`Failed to generate prayer: ${error.message}. Please try again.`);
          setIsApiUnavailable(false);
        }
      } else {
        setError('An unexpected error occurred while generating the prayer. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const restartPrayer = () => {
    setStep(0);
    setFormData({
      name: '',
      city: '',
      prayerFor: '',
      prayerTopic: '',
      language: '',
    });
    setPrayerResponse(null);
    setError('');
    window.speechSynthesis.cancel();
  };

  const renderInput = () => {
    switch (STEPS[step]) {
      case 'NAME':
        return (
          <div className="space-y-4">
            <div className="relative">
              <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleInputChange}
                className="input-field pl-10 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                required
                aria-label="Your Name"
              />
            </div>
            <div className="relative">
              <Globe className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                name="city"
                placeholder="Your City"
                value={formData.city}
                onChange={handleInputChange}
                className="input-field pl-10 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                required
                aria-label="Your City"
              />
            </div>
            <div className="relative">
              <Users className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <div className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-md">
                <label className="block text-sm font-medium text-slate-700 mb-2">Who is this prayer for?</label>
                <div className="flex space-x-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="prayerFor"
                      value="self"
                      checked={formData.prayerFor === 'self'}
                      onChange={handleInputChange}
                      className="mr-2 text-orange-500 focus:ring-orange-500"
                    />
                    <span>Myself</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="prayerFor"
                      value="others"
                      checked={formData.prayerFor === 'others'}
                      onChange={handleInputChange}
                      className="mr-2 text-orange-500 focus:ring-orange-500"
                    />
                    <span>Others</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      case 'TOPIC':
        return (
          <div className="space-y-4">
            <div className="relative">
              <Book className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                name="prayerTopic"
                placeholder="Enter your prayer topic"
                value={formData.prayerTopic}
                onChange={handleInputChange}
                className="input-field pl-10 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                required
                aria-label="Prayer Topic"
              />
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2 text-slate-700">Popular Prayer Topics:</h3>
              <div className="flex flex-wrap gap-2">
                {POPULAR_PRAYER_TOPICS.map((topic, index) => (
                  <motion.button
                    key={topic.topic}
                    onClick={() => setFormData(prev => ({ ...prev, prayerTopic: topic.topic }))}
                    className={`px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200 ${
                      formData.prayerTopic === topic.topic
                        ? 'bg-orange-500 text-white'
                        : 'bg-gray-200 text-slate-700 hover:bg-orange-100'
                    }`}
                    style={{
                      fontSize: `${Math.max(0.8, Math.min(1.2, 1 + topic.popularity * 0.4))}rem`,
                    }}
                    initial={{ scale: 1, opacity: 0 }}
                    animate={{ 
                      scale: formData.prayerTopic === topic.topic ? 1.05 : 1,
                      opacity: 1
                    }}
                    transition={{ 
                      duration: 0.2,
                      delay: index * 0.03
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {topic.topic}
                  </motion.button>
                ))}
              </div>
            </div>
          </div>
        );
      case 'LANGUAGE':
        return (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {LANGUAGES.map((lang) => {
              const FlagComponent = lang.flag;
              return (
                <motion.label
                  key={lang.code}
                  className={`flex flex-col items-center p-4 border rounded-lg cursor-pointer transition-all ${
                    formData.language === lang.code
                      ? 'bg-orange-100 border-orange-500'
                      : 'bg-white border-gray-300 hover:bg-orange-50'
                  }`}
                  initial={{ scale: 1 }}
                  animate={{ scale: formData.language === lang.code ? 1.05 : 1 }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <input
                    type="radio"
                    name="language"
                    value={lang.code}
                    checked={formData.language === lang.code}
                    onChange={handleInputChange}
                    className="sr-only"
                  />
                  <FlagComponent className="w-16 h-10 mb-2 rounded shadow-sm" />
                  <span className="text-sm font-medium text-center">{lang.name}</span>
                </motion.label>
              );
            })}
          </div>
        );
      default:
        return null;
    }
  };

  const isStepValid = () => {
    switch (STEPS[step]) {
      case 'NAME':
        return formData.name.trim() !== '' && formData.city.trim() !== '' && (formData.prayerFor === 'self' || formData.prayerFor === 'others');
      case 'TOPIC':
        return formData.prayerTopic.trim() !== '';
      case 'LANGUAGE':
        return formData.language !== '';
      default:
        return true;
    }
  };

  const sharePrayer = () => {
    if (prayerResponse) {
      const shareText = `🙏 My Personalized Prayer from Prayer Partner

Dear ${formData.name}'s Prayer:
${prayerResponse.prayer}

📖 Bible Verse:
${prayerResponse.bibleVerse.text}
- ${prayerResponse.bibleVerse.reference}

Generate your own prayer: https://prayerpartner.in`;

      if (navigator.share) {
        navigator.share({
          title: 'My Prayer from Prayer Partner',
          text: shareText,
          url: 'https://prayerpartner.in'
        }).catch(error => {
          console.error('Error sharing:', error);
          fallbackShare(shareText);
        });
      } else {
        fallbackShare(shareText);
      }
    }
  };

  const fallbackShare = (shareText: string) => {
    navigator.clipboard.writeText(shareText)
      .then(() => {
        alert('Prayer copied to clipboard! You can now share it with others.');
      })
      .catch(err => {
        console.error('Could not copy text:', err);
        alert('Could not copy the prayer. Please try again.');
      });
  };

  const handleContactInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setContactForm(prev => ({ ...prev, [name]: value }));
  };

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    setCaptcha({
      question: `What is ${num1} + ${num2}?`,
      answer: num1 + num2
    });
  };

  const handleContactSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (parseInt(userCaptchaAnswer) !== captcha.answer) {
      alert('Incorrect CAPTCHA answer. Please try again.');
      generateCaptcha();
      setUserCaptchaAnswer('');
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          access_key: '22873069-8044-46c0-9a66-58df08e5b1a9',
          ...contactForm,
          captcha: userCaptchaAnswer
        })
      });
      const result = await response.json();
      if (result.success) {
        setShowSuccessModal(true);
        setContactForm({ name: '', email: '', message: '' });
        setUserCaptchaAnswer('');
        generateCaptcha();
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting the form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-slate-100 to-slate-200">
      <header className="bg-white shadow-md">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <Feather className="text-slate-600 mr-2" />
            <h1 className="text-2xl font-bold text-slate-800">Prayer Partner</h1>
            <span className="ml-2 px-2 py-1 text-xs font-semibold text-white bg-orange-500 rounded-full">Beta</span>
          </div>
          <nav className="hidden md:flex space-x-4">
            <a href="#home" className="text-slate-600 hover:text-orange-500">Home</a>
            <a href="#about" className="text-slate-600 hover:text-orange-500">About</a>
            <a href="#contact" className="text-slate-600 hover:text-orange-500">Contact</a>
          </nav>
          <KofiButton
            username="R5R815RCDR"
            label="Donate"
            preset="thin"
            backgroundColor="#72a4f2"
          />
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 py-8 md:pb-8 pb-20">
        <section id="home" className="mb-16">
          <div className="text-center text-slate-800 mb-8">
            <h2 className="text-4xl md:text-5xl font-bold mb-4">Welcome to Prayer Partner</h2>
            <p className="text-xl md:text-2xl">Your personal companion for spiritual growth and reflection</p>
          </div>
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-lg shadow-xl overflow-hidden"
          >
            <div className="p-8">
              <div className="mb-10">
                <div className="flex justify-between items-center">
                  {STEPS.map((s, index) => (
                    <div key={s} className={`flex flex-col items-center ${index <= step ? 'text-orange-500' : 'text-gray-400'}`}>
                      <motion.div 
                        whileHover={{ scale: 1.1 }}
                        className={`w-10 h-10 rounded-full flex items-center justify-center mb-2 ${index <= step ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-600'}`}
                      >
                        {index + 1}
                      </motion.div>
                      <span className="text-sm font-medium">{s}</span>
                    </div>
                  ))}
                </div>
                <motion.div 
                  className="mt-4 h-2 bg-gray-200 rounded-full overflow-hidden"
                  initial={{ width: 0 }}
                  animate={{ width: '100%' }}
                  transition={{ duration: 0.5 }}
                >
                  <motion.div
                    className="h-full bg-orange-500 rounded-full"
                    initial={{ width: 0 }}
                    animate={{ width: `${(step / (STEPS.length - 1)) * 100}%` }}
                    transition={{ duration: 0.5 }}
                  />
                </motion.div>
              </div>
              <AnimatePresence mode="wait">
                <motion.div
                  key={step}
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -50 }}
                  transition={{ duration: 0.3 }}
                  className="space-y-8"
                >
                  <h2 className="text-3xl font-semibold text-slate-700 text-center">
                    {PROMPTS[STEPS[step]]}
                  </h2>
                  {STEPS[step] !== 'PRAYER' ? (
                    renderInput()
                  ) : (
                    <div className="space-y-8">
                      {prayerResponse && (
                        <>
                          <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.2 }}
                            className="bg-orange-50 p-8 rounded-lg shadow-inner relative"
                          >
                            <h3 className="text-2xl font-semibold mb-4 text-orange-800 flex items-center">
                              <Book className="mr-2" /> Your Personalized Prayer
                            </h3>
                            <p className="text-slate-700 text-lg whitespace-pre-line">{prayerResponse.prayer}</p>
                          </motion.div>
                          <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.4 }}
                            className="bg-green-50 p-8 rounded-lg shadow-inner"
                          >
                            <h3 className="text-2xl font-semibold mb-4 text-green-800 flex items-center">
                              <Book className="mr-2" /> Relevant Bible Verse
                            </h3>
                            <p className="text-slate-700 text-lg italic">{prayerResponse.bibleVerse.text}</p>
                            <p className="text-slate-500 mt-2">{prayerResponse.bibleVerse.reference}</p>
                          </motion.div>
                        </>
                      )}
                    </div>
                  )}
                  {error && (
                    <div className="text-red-500 font-bold text-center text-lg">
                      <p>{error}</p>
                      <button
                        onClick={generatePrayer}
                        className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                      >
                        Retry
                      </button>
                    </div>
                  )}
                </motion.div>
              </AnimatePresence>
              <div className="mt-10 flex justify-end">
                {step < STEPS.length - 2 && (
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={nextStep}
                    disabled={!isStepValid()}
                    className={`px-8 py-3 rounded-full text-white font-semibold text-lg transition-colors duration-300 flex items-center ${
                      isStepValid() ? 'bg-orange-500 hover:bg-orange-600' : 'bg-gray-400 cursor-not-allowed'
                    }`}
                  >
                    Next <ChevronRight className="ml-2" />
                  </motion.button>
                )}
                {step === STEPS.length - 2 && (
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={generatePrayer}
                    disabled={isLoading || !isStepValid() || isApiUnavailable}
                    className={`px-8 py-3 rounded-full text-white font-semibold text-lg transition-colors duration-300 flex items-center ${
                      isLoading || !isStepValid() || isApiUnavailable ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'
                    }`}
                  >
                    {isLoading ? (
                      <>
                        <Loader className="animate-spin mr-2" />
                        Generating...
                      </>
                    ) : isApiUnavailable ? (
                      'Service Unavailable'
                    ) : (
                      <>
                        Generate Prayer <ChevronRight className="ml-2" />
                      </>
                    )}
                  </motion.button>
                )}
                {step === STEPS.length - 1 && (
                  <div className="flex space-x-4">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={sharePrayer}
                      className="px-8 py-3 rounded-full bg-blue-500 text-white font-semibold text-lg hover:bg-blue-600 transition-colors duration-300 flex items-center"
                    >
                      <Share2 className="mr-2" /> Share Your Prayer
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={restartPrayer}
                      className="px-8 py-3 rounded-full bg-orange-500 text-white font-semibold text-lg hover:bg-orange-600 transition-colors duration-300 flex items-center"
                    >
                      New Prayer <ChevronRight className="ml-2" />
                    </motion.button>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        </section>

        <section id="about" className="mb-16 bg-white rounded-lg shadow-xl p-8">
          <h2 className="text-3xl font-bold text-slate-800 mb-4">About Prayer Partner</h2>
          <p className="text-slate-700 mb-4">
            The main reason behind starting Prayer Partner is to help those people who are shy, and they hesitate and those who really want to pray but they don't know how to connect with God. This is where Prayer Partner took inception. Prayer Partner is your personal companion for spiritual growth and reflection. Our AI-powered platform generates personalized prayers tailored to your specific needs and circumstances, helping you deepen your connection with your faith.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex flex-col items-center text-center">
              <Heart className="text-orange-500 mb-2" size={48} />
              <h3 className="text-xl font-semibold mb-2">Personalized Prayers</h3>
              <p className="text-slate-600">Receive prayers crafted specifically for your unique situation and needs.</p>
            </div>
            <div className="flex flex-col items-center text-center">
              <Book className="text-orange-500 mb-2" size={48} />
              <h3 className="text-xl font-semibold mb-2">Biblical Wisdom</h3>
              <p className="text-slate-600">Each prayer is accompanied by a relevant Bible verse to inspire and guide you.</p>
            </div>
            <div className="flex flex-col items-center text-center">
              <Globe className="text-orange-500 mb-2" size={48} />
              <h3 className="text-xl font-semibold mb-2">Multi-Language Support</h3>
              <p className="text-slate-600">Access prayers in multiple languages to cater to a global community of believers.</p>
            </div>
          </div>
        </section>

        <section id="contact" className="bg-gradient-to-br from-orange-100 to-orange-200 rounded-lg shadow-xl p-8 mb-16">
          <h2 className="text-4xl font-bold text-orange-800 mb-6 text-center">Get in Touch</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-4">
              <p className="text-slate-700 text-lg">
                We'd love to hear from you! Whether you have a question about Prayer Partner, need support, or just want to share your experience, we're here to listen.
              </p>
              <div className="flex items-center space-x-2 text-orange-700">
                <Mail className="h-5 w-5" />
                <span>support@prayerpartner.in</span>
              </div>
              <div className="flex items-center space-x-2 text-orange-700">
                <MapPin className="h-5 w-5" />
                <span>Kota, Rajasthan, India</span>
              </div>
            </div>
            <form onSubmit={handleContactSubmit} className="space-y-4 bg-white p-6 rounded-lg shadow-md">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={contactForm.name}
                  onChange={handleContactInputChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={contactForm.email}
                  onChange={handleContactInputChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={contactForm.message}
                  onChange={handleContactInputChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                  rows={4}
                ></textarea>
              </div>
              <div>
                <label htmlFor="captcha" className="block text-sm font-medium text-gray-700 mb-1">{captcha.question} (CAPTCHA)</label>
                <input
                  type="number"
                  id="captcha"
                  name="captcha"
                  value={userCaptchaAnswer}
                  onChange={(e) => setUserCaptchaAnswer(e.target.value)}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {isSubmitting ? (
                  <>
                    <Loader className="animate-spin inline-block mr-2" />
                    Sending...
                  </>
                ) : (
                  'Send Message'
                )}
              </button>
            </form>
          </div>
        </section>
      </main>

      <footer className="bg-slate-800 text-white py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <h2 className="text-2xl font-bold flex items-center">
                <Feather className="mr-2" />
                Prayer Partner
              </h2>
              <p className="text-sm">Your companion for spiritual growth</p>
            </div>
            <nav className="flex space-x-4">
              <a href="#home" className="hover:text-orange-400">Home</a>
              <a href="#about" className="hover:text-orange-400">About</a>
              <a href="#contact" className="hover:text-orange-400">Contact</a>
            </nav>
          </div>
          <div className="mt-8 text-center text-sm">
            <p>&copy; 2024 Prayer Partner. All rights reserved.</p>
          </div>
        </div>
      </footer>

      <nav className="fixed bottom-0 left-0 right-0 z-50 bg-white border-t border-gray-200 md:hidden">
        <div className="grid grid-cols-3 h-16">
          <a href="#home" className="flex flex-col items-center justify-center text-slate-600 hover:text-orange-500">
            <Home size={24} />
            <span className="text-xs mt-1">Home</span>
          </a>
          <a href="#about" className="flex flex-col items-center justify-center text-slate-600 hover:text-orange-500">
            <Info size={24} />
            <span className="text-xs mt-1">About</span>
          </a>
          <a href="#contact" className="flex flex-col items-center justify-center text-slate-600 hover:text-orange-500">
            <Mail size={24} />
            <span className="text-xs mt-1">Contact</span>
          </a>
        </div>
      </nav>
      {showSuccessModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-xl">
            <h3 className="text-2xl font-bold mb-4">Thank You!</h3>
            <p className="mb-6">Your message has been sent successfully. We'll get back to you soon.</p>
            <button
              onClick={() => setShowSuccessModal(false)}
              className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition-colors duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}